* {
  box-sizing: border-box;
  margin:0;
  padding: 0;
  font-family:'Encode Sans Expanded', sans-serif;
}
.logoColor {
  width: auto;
  height: 80px;
  object-fit:cover;
  margin-top: 10px;

}

.logoColorName {
  width: 100%;
  
  z-index: -1;
}

textarea {
  width: 100%;
}
